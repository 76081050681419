<template>
  <div>
    <mybb-text>{{ t('text') }}</mybb-text>

    <v-row justify="center" class="my-3">
      <v-col cols="8">
        <v-divider />
      </v-col>
    </v-row>

    <activities-on-day
      v-for="date of dates"
      :key="date"
      :gathering="gathering"
      :activities-by-date="activitiesByDate"
      :date="date"
      :subscriptions="subscriptions"
      @activity-subscription="handleActivitySubscription"
      @activity-settings="payload => $emit('activity-settings', payload)"
    />
  </div>
</template>

<script>
import dateFormat from 'date-fns/format'

import ActivitiesOnDay from './ActivitiesOnDay'

export default {
  name: 'ActivitiesForm',
  components: { ActivitiesOnDay },
  model: {
    prop: 'participant'
  },
  props: {
    participant: {
      type: Object
    },
    activities: {
      type: Array,
      default: () => []
    },
    gathering: {
      type: Object
    },
    internal: {
      type: Boolean
    }
  },
  data() {
    return {
      localSubscriptions: {}
    }
  },
  computed: {
    subscriptions() {
      return this.participant ? this.$get(this.participant, 'activitiesSubscription') : this.localSubscriptions
    },
    hasActivities() {
      return this.$get(this.activities, 'length', 0) > 0
    },
    activitiesByDate() {
      const activitiesByDate = {}

      for (const activity of this.activities || []) {
        const dateKey = this.dateKey(activity.date)

        if (!Array.isArray(activitiesByDate[dateKey])) {
          activitiesByDate[dateKey] = []
        }

        activitiesByDate[dateKey].push(activity)
      }

      return activitiesByDate
    },
    dates() {
      return Object.keys(this.activitiesByDate).sort((date1, date2) => new Date(date1) - new Date(date2))
    }
  },
  methods: {
    t(key, params) {
      return this.$t(`mybb.subscriptionForm.activities.${key}`, params)
    },
    dateKey(date) {
      if (!date) return ''

      return dateFormat(new Date(date), 'yyyy-MM-dd')
    },
    handleActivitySubscription(payload) {
      if (this.participant) {
        this.$emit('activity-subscription', payload)
      } else {
        const dates = this.$get(this.localSubscriptions, payload.date)

        this.$set(this.localSubscriptions, payload.date, { ...dates, [payload.interval]: payload.value })
      }
    }
  }
}
</script>
