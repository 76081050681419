<template>
  <div>
    <mybb-radios v-model="wantHosting" :label="t('text')" :items="radioItems" weight="semi-bold" row />

    <div v-if="wantHosting && canChooseHosting">
      <v-row justify="center" class="my-3">
        <v-col cols="8">
          <v-divider />
        </v-col>
      </v-row>

      <mybb-text class="mb-2" weight="semi-bold">{{ t('checkbox') }}</mybb-text>
      <div v-for="night of nights" :key="night.toISOString()" class="mb-3">
        <v-checkbox
          v-if="!isEveArrival(night) || configuration.chooseEveArrivalAllowed"
          v-model="nightsWanted"
          :value="night"
          color="mybb-primary-lighten1"
          class="mt-0 mr-7 d-inline-block"
          multiple
          dense
          hide-details
        >
          <template v-slot:label>
            <mybb-text class="Checkbox-Label">{{ formatDate(night) }}</mybb-text>
          </template>
        </v-checkbox>
      </div>

      <mybb-textarea v-model="comment" :label="t('comment')" icon="mdi-comment-text" class="my-3" rows="3" />
    </div>

    <mybb-radios
      v-if="canAskTransfer"
      v-model="wantTransfer"
      :label="t('wantTransfer')"
      :items="radioItems"
      weight="semi-bold"
      row
    />
  </div>
</template>

<script>
import dateFormat from 'date-fns/format'

export default {
  name: 'HostingForm',
  model: {
    prop: 'participant'
  },
  props: {
    gathering: {
      type: Object,
      validator(prop) {
        return (
          !prop ||
          (prop &&
            prop.hasOwnProperty('hostingConfiguration') &&
            prop.hasOwnProperty('transportConfiguration') &&
            prop.hasOwnProperty('beginDate') &&
            prop.hasOwnProperty('endDate'))
        )
      }
    },
    participant: {
      type: Object
    }
  },
  data() {
    return {
      hosting: {
        wantHosting: null,
        nightsWanted: [],
        comment: null,
        wantTransfer: null
      }
    }
  },
  computed: {
    wantHosting: {
      get() {
        return this.participant ? this.$get(this.participant, 'hosting.wantHosting') : this.hosting.wantHosting
      },
      set(value) {
        this.setProperty('wantHosting', value)
      }
    },
    nightsWanted: {
      get() {
        return this.participant ? this.$get(this.participant, 'hosting.nightsWanted') : this.hosting.nightsWanted
      },
      set(value) {
        this.setProperty('nightsWanted', value)
      }
    },
    comment: {
      get() {
        return this.participant ? this.$get(this.participant, 'hosting.comment') : this.hosting.comment
      },
      set(value) {
        this.setProperty('comment', value)
      }
    },
    wantTransfer: {
      get() {
        return this.participant ? this.$get(this.participant, 'hosting.wantTransfer') : this.hosting.wantTransfer
      },
      set(value) {
        this.setProperty('wantTransfer', value)
      }
    },
    configuration() {
      return this.$get(this.gathering, 'hostingConfiguration')
    },
    radioItems() {
      return [{ label: this.t('yes'), value: true }, { label: this.t('no'), value: false }]
    },
    nights() {
      const nights = []
      const date = new Date(this.gathering.beginDate)
      const eveArrival = this.configuration ? this.configuration.eveArrival : false

      if (this.gathering.endDate === this.gathering.beginDate && !eveArrival) return [date]

      if (eveArrival) {
        date.setDate(date.getDate() - 1)
      }

      while (date.toISOString().slice(0, 10) !== this.gathering.endDate) {
        nights.push(new Date(date))
        date.setDate(date.getDate() + 1)
      }

      return nights
    },
    canChooseHosting() {
      if (!this.configuration) return false

      return this.configuration.chooseNightsAllowed
    },
    canAskTransfer() {
      return this.wantHosting && this.$get(this.gathering, 'transportConfiguration.transfertOptionEnabled')
    }
  },
  methods: {
    t(key, params) {
      return this.$t(`mybb.subscriptionForm.hosting.${key}`, params)
    },
    setProperty(property, value) {
      if (!this.participant) {
        this.hosting[property] = value
        return
      }

      const hosting = this.$get(this.participant, 'hosting', {})
      this.$emit('input', { ...this.participant, hosting: { ...hosting, [property]: value } })
    },
    isEveArrival(date) {
      const firstDate = new Date(this.gathering.beginDate)
      firstDate.setDate(firstDate.getDate() - 1)

      return dateFormat(date, 'yyyy-MM-dd') === dateFormat(firstDate, 'yyyy-MM-dd')
    },
    formatDate(date) {
      if (!date) return null

      const formattedDate = dateFormat(new Date(date), 'dd/MM/yy')

      return this.isEveArrival(date) ? this.t('priorDate', { date: formattedDate }) : formattedDate
    }
  }
}
</script>

<style lang="scss" scoped>
.Checkbox-Label {
  vertical-align: top;
}
</style>
