<template>
  <v-dialog
    v-model="modal"
    fullscreen
    transition="dialog-bottom-transition"
    content-class="SubscriptionFormDialog"
    persistent
    no-click-animation
  >
    <v-card class="px-5" tile>
      <div class="Card">
        <!-- Header -->
        <v-card-title>
          <mybb-text weight="bold" size="20">{{ t('title', { name }) }}</mybb-text>
          <v-spacer />
          <v-btn icon @click="cancel">
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-card-title>

        <!-- Content -->
        <v-card-text class="pb-5">
          <mybb-text>{{ t('text', { roc }) }}</mybb-text>

          <subscription-form
            v-model="participant"
            :gathering-uuid="gatheringUuid"
            :gathering="gathering"
            for-user
            internal
            @activity-settings="handleActivitySettings"
          />

          <div class="mt-10 text-center">
            <mybb-btn color="mybb-grey" class="mr-10" @click="cancel">{{ t('cancel') }}</mybb-btn>
            <mybb-btn :disabled="!canSubmit" color="mybb-success" @click="submitModal = true">
              {{ t('submit') }}
            </mybb-btn>
          </div>
        </v-card-text>
      </div>
    </v-card>

    <!-- Submit modal -->
    <modal v-model="submitModal" :title="tm('title')">
      <mybb-text>{{ tm(isPresent ? 'text-accept' : 'text-refuse', { name }) }}</mybb-text>

      <div class="mt-10 text-center">
        <mybb-btn color="mybb-grey" class="mr-10" @click="cancel">{{ tm('cancel') }}</mybb-btn>
        <mybb-btn color="mybb-success" @click="submit">{{ tm('confirm') }}</mybb-btn>
      </div>
    </modal>

    <!-- Cancel modal -->
    <modal v-model="cancelModal" :title="tcm('title')">
      <mybb-text>{{ tcm('text') }}</mybb-text>

      <div class="mt-10 text-center">
        <mybb-btn color="mybb-grey" class="mr-10" @click="cancelModal = false">{{ tcm('cancel') }}</mybb-btn>
        <mybb-btn color="mybb-success" @click="modal = false">{{ tcm('confirm') }}</mybb-btn>
      </div>
    </modal>
  </v-dialog>
</template>

<script>
import dateFormat from 'date-fns/format'
import Categories from 'mybb-categories'

import { GET_ALL_SETTINGS_GATHERING_FOR_SUBSCRIPTION } from '@/graphql/Gatherings/GetGathering'
import { PARTICIPANT } from '@/graphql/Participant'
import { SUBSCRIBE_INTERNAL_USER } from '@/graphql/Subscription'

import SubscriptionForm from '@/components/mybb/subscriptionForm/SubscriptionForm'
import Modal from '@/components/mybb/Modal'

import { yup } from '@/mixins'

export default {
  name: 'SubscriptionFormDialog',
  mixins: [yup],
  components: { SubscriptionForm, Modal },
  model: {
    prop: 'visible',
    event: 'change'
  },
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    gatheringUuid: {
      type: String
    },
    participantUuid: {
      type: String
    }
  },
  data() {
    return {
      activitySettings: {},
      submitModal: false,
      cancelModal: false
    }
  },
  computed: {
    modal: {
      get() {
        return this.visible
      },
      set(value) {
        this.$emit('change', value)

        if (!value) {
          this.submitModal = false
          this.cancelModal = false
          this.$apollo.queries.participant.refetch()
        }
      }
    },
    isHybrid() {
      return this.gathering && this.gathering.isPhysical && this.gathering.isVirtual
    },
    name() {
      return this.gathering ? this.gathering.name : null
    },
    roc() {
      if (!this.gathering) return null

      const roc = this.gathering.users.find(user => user.gatheringUserType === this.$const.gatheringUserType.ROC)

      if (!roc || (roc && !roc.user)) return null

      return `${roc.user.firstName} ${roc.user.lastName}`
    },
    canSubmit() {
      if (!this.gathering || !this.participant) return false

      const category = Categories.forVersionAndCategoryId(this.gathering.categoryVersion, this.participant.category)

      // Presence
      const { confirmed, refused } = this.$const.invitationStatus
      const invitationStatus = this.$get(this.participant, 'invitationStatus')
      const participationType = this.$get(this.participant, 'participationType')

      if (invitationStatus === refused) return true
      if (this.isHybrid && participationType === this.$const.activity.participationType.virtual) return true

      const presenceValid = [confirmed, refused].includes(invitationStatus)

      // Mobile Phone
      const mobile = this.$get(this.participant, 'mobilePhone')
      if (!mobile && invitationStatus === confirmed) return false
      if (!this.checkMobilePhoneFormat(mobile) && invitationStatus === confirmed) return false

      // Activities
      let activitiesValid = false
      if (Object.keys(this.activitySettings).length === 0) {
        activitiesValid = true
      } else if (this.$get(this.participant, 'activitiesSubscription')) {
        const activitiesByDate = Object.keys(this.participant.activitiesSubscription).reduce(
          (acc, date) => ({ ...acc, [date]: Object.keys(this.participant.activitiesSubscription[date]).length }),
          {}
        )

        activitiesValid = Object.keys(this.activitySettings).every(
          date => this.activitySettings[date] <= activitiesByDate[date]
        )
      }

      // Hosting
      const hostingIsConfigured = Boolean(this.$get(this.gathering, 'hostingConfiguration'))
      const hasHostings = this.$get(this.gathering, 'hostings', []).length > 0
      const haveToChooseNights = this.$get(this.gathering, 'hostingConfiguration.chooseNightsAllowed')
      const canChooseHosting = category.modules.includes(Categories.constants.Module.Hosting)

      let hostingValid = !hostingIsConfigured || !hasHostings || false || !canChooseHosting

      if (this.hostingInput && hostingIsConfigured && hasHostings && canChooseHosting) {
        if (!this.hostingInput.wantHosting) hostingValid = true
        else {
          hostingValid = haveToChooseNights
            ? this.hostingInput.nightsWanted && this.hostingInput.nightsWanted.length > 0
            : !this.hostingInput.nightsWanted || this.hostingInput.nightsWanted.length <= 0
        }
      }

      // Transport
      const canChooseTransport = category.modules.includes(Categories.constants.Module.Transport)

      let transportValid = !canChooseTransport || false
      if (canChooseTransport && this.transportInput) {
        if (!this.transportInput.wantTransport) transportValid = true
        else {
          const {
            preferenceModes,
            goDepartureDate,
            goDepartureHour,
            goDeparturePlace,
            goArrivalPlace,
            backDepartureDate,
            backDepartureHour,
            backDeparturePlace,
            backArrivalPlace
          } = this.transportInput

          transportValid =
            preferenceModes.length > 0 &&
            goDepartureDate &&
            goDepartureHour &&
            goDeparturePlace &&
            goArrivalPlace &&
            backDepartureDate &&
            backDepartureHour &&
            backDeparturePlace &&
            backArrivalPlace
        }
      }

      return presenceValid && activitiesValid && hostingValid && transportValid
    },
    hasChanges() {
      const presenceChanged = this.$get(this.participant, 'invitationStatus') !== this.$const.invitationStatus.pending

      return presenceChanged || this.activityInput || this.hostingInput || this.transportInput
    },
    isPresent() {
      return this.$get(this.participant, 'invitationStatus') === this.$const.invitationStatus.confirmed
    },
    mobileIsValid() {
      return this.$get(this.participant, 'invitationStatus')
    },
    subscriptionPayload() {
      return {
        participantUuid: this.participantUuid,
        present: this.isPresent,
        participationType: this.$get(this.participant, 'participationType'),
        mobilePhone: this.$get(this.participant, 'mobilePhone'),
        gatheringUuid: this.gatheringUuid,
        activityInput: this.activityInput,
        hostingInput: this.hostingInput,
        transportInput: this.transportInput
      }
    },
    activityInput() {
      const activityInput = []

      for (const date in this.$get(this.participant, 'activitiesSubscription', {})) {
        const activitiesOnDate = this.$get(this.participant, ['activitiesSubscription', date], {})

        for (const interval in activitiesOnDate) {
          if (!activitiesOnDate[interval]) continue

          activityInput.push({
            date,
            interval,
            activityUuid: activitiesOnDate[interval]
          })
        }
      }

      return activityInput.length ? activityInput : null
    },
    hostingInput() {
      const hostingInput = this.$get(this.participant, 'hosting')

      if (!hostingInput) return null

      const input = JSON.parse(JSON.stringify(hostingInput))

      if (Array.isArray(input.nightsWanted)) {
        input.nightsWanted = input.nightsWanted.map(date => dateFormat(new Date(date), 'yyyy-MM-dd'))
      }

      return input || null
    },
    transportInput() {
      const transportInput = this.$get(this.participant, 'transportNote')

      if (!transportInput) return null

      transportInput.wantTransport = Array.isArray(transportInput.preferenceModes)

      if (transportInput.goDepartureDate) {
        transportInput.goDepartureDate = dateFormat(new Date(transportInput.goDepartureDate), 'yyyy-MM-dd')
      }

      if (transportInput.backDepartureDate) {
        transportInput.backDepartureDate = dateFormat(new Date(transportInput.backDepartureDate), 'yyyy-MM-dd')
      }

      return transportInput
    }
  },
  apollo: {
    gathering: {
      query: GET_ALL_SETTINGS_GATHERING_FOR_SUBSCRIPTION,
      variables() {
        return { gatheringUuid: this.gatheringUuid }
      },
      skip() {
        return !this.visible
      }
    },
    participant: {
      query: PARTICIPANT,
      variables() {
        return {
          participantUuid: this.participantUuid
        }
      },
      skip() {
        return !this.participantUuid
      }
    }
  },
  methods: {
    t(key, params) {
      return this.$t(`subscription-form-dialog.${key}`, params)
    },
    tm(key, params) {
      return this.t(`modal.${key}`, params)
    },
    tcm(key, params) {
      return this.t(`cancel-modal.${key}`, params)
    },
    handleActivitySettings({ date, count }) {
      this.$set(this.activitySettings, date, count)
    },
    cancel() {
      if (this.hasChanges) {
        this.cancelModal = true
      } else {
        this.modal = false
      }
    },
    async submit() {
      await this.$apollo.mutate({
        mutation: SUBSCRIBE_INTERNAL_USER,
        variables: this.subscriptionPayload
      })

      this.$emit('refresh')
      this.modal = false
    }
  }
}
</script>

<style lang="scss" scoped>
.SubscriptionFormDialog {
  .Card {
    margin: 0 auto;
    width: 80%;
    max-width: 1760px;
  }
}
</style>
