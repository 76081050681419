<template>
  <div>
    <mybb-radios v-model="wantTransport" :label="t('header')" :items="radioItems" weight="semi-bold" row />

    <div v-if="wantTransport">
      <v-row justify="center" class="my-3">
        <v-col cols="8">
          <v-divider />
        </v-col>
      </v-row>

      <mybb-radios
        v-model="modes"
        :label="t('transportModeLabel')"
        :items="transportModes"
        text-color="mybb-grey-lighten1"
        size="12"
        two-rows
      />

      <div v-if="preferTrain || preferPlane">
        <mybb-text weight="semi-bold" class="mt-5 mb-2">
          {{ t('transportInformationHeader') }}
        </mybb-text>

        <div v-if="!internal">
          <!-- TRAIN INFORMATIONS -->
          <div v-if="preferTrain">
            <v-row>
              <v-col cols="3">
                <mybb-text-field v-model="transportNote.sncfNumber" :label="t('sncfNumber')" icon="mdi-train" />
              </v-col>
              <v-col cols="6">
                <mybb-text-field v-model="transportNote.sncfCard" :label="t('sncfCard')" icon="mdi-train" />
              </v-col>
              <v-col cols="3">
                <date-picker
                  v-model="transportNote.sncfExpirationDate"
                  :label="t('sncfExpirationDate')"
                  :min="today"
                  icon="mdi-train"
                />
              </v-col>
            </v-row>
          </div>

          <!-- PLANE INFORMATIONS -->
          <div v-if="preferPlane">
            <v-row>
              <v-col cols="3">
                <mybb-text-field
                  v-model="transportNote.airFranceNumber"
                  :label="t('airFranceNumber')"
                  icon="mdi-airplane"
                />
              </v-col>
              <v-col cols="6">
                <mybb-text-field
                  v-model="transportNote.airFranceCard"
                  :label="t('airFranceCard')"
                  icon="mdi-airplane"
                />
              </v-col>
              <v-col cols="3">
                <date-picker
                  v-model="transportNote.airFranceExpirationDate"
                  :label="t('airFranceExpirationDate')"
                  :min="today"
                  icon="mdi-airplane"
                />
              </v-col>
            </v-row>
          </div>
        </div>

        <!-- GO PREFERENCES -->
        <mybb-text class="mt-5 mb-2" weight="semi-bold">
          {{ t('goHeader') }}
        </mybb-text>
        <v-row>
          <v-col cols="3">
            <date-picker v-model="goDepartureDate" :label="t('goDepartureDate')" :min="today" />
          </v-col>
          <v-col cols="3">
            <time-picker v-model="goDepartureHour" :label="t('goDepartureHour')" />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <mybb-text-field v-model="goDeparturePlace" :label="t('goDeparturePlace')" icon="mdi-map-marker" />
          </v-col>
          <v-col cols="6">
            <mybb-text-field v-model="goArrivalPlace" :label="t('goArrivalPlace')" icon="mdi-map-marker" />
          </v-col>
        </v-row>

        <!-- BACK PREFERENCES -->
        <mybb-text class="mt-5 mb-2" weight="semi-bold">
          {{ t('backHeader') }}
        </mybb-text>
        <v-row>
          <v-col cols="3">
            <date-picker v-model="backDepartureDate" :label="t('backDepartureDate')" :min="today" />
          </v-col>
          <v-col cols="3">
            <time-picker v-model="backDepartureHour" :label="t('backDepartureHour')" />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <mybb-text-field v-model="backDeparturePlace" :label="t('backDeparturePlace')" icon="mdi-map-marker" />
          </v-col>
          <v-col cols="6">
            <mybb-text-field v-model="backArrivalPlace" :label="t('backArrivalPlace')" icon="mdi-map-marker" />
          </v-col>
        </v-row>

        <!-- COMPLEMENTARY INFORMATIONS -->
        <mybb-text class="mt-5 mb-2" weight="semi-bold">
          {{ t('complementaryInformations') }}
        </mybb-text>
        <mybb-textarea v-model="comments" :label="t('comments')" icon="mdi-comment-text" rows="3" />
      </div>
    </div>
  </div>
</template>

<script>
import DatePicker from '@/components/mybb/ui/DatePicker'
import TimePicker from '@/components/mybb/ui/TimePicker'

const BOTH = 'BOTH'

export default {
  name: 'TransportForm',
  components: {
    DatePicker,
    TimePicker
  },
  model: {
    prop: 'participant'
  },
  props: {
    participant: {
      type: Object
    },
    internal: {
      type: Boolean
    }
  },
  data() {
    return {
      transportNote: {
        preferenceModes: undefined,
        sncfNumber: '',
        sncfCard: '',
        sncfExpirationDate: new Date(),
        airFranceNumber: '',
        airFranceCard: '',
        airFranceExpirationDate: new Date(),
        goDepartureDate: new Date(),
        goDepartureHour: null,
        goDeparturePlace: '',
        goArrivalPlace: '',
        backDepartureDate: new Date(),
        backDepartureHour: null,
        backDeparturePlace: '',
        backArrivalPlace: '',
        complementaryInformations: '',
        comments: ''
      }
    }
  },
  computed: {
    wantTransport: {
      get() {
        const preferenceModes = this.participant
          ? this.$get(this.participant, 'transportNote.preferenceModes')
          : this.transportNote.preferenceModes

        return preferenceModes !== undefined ? Array.isArray(preferenceModes) : null
      },
      set(value) {
        if (this.participant) {
          this.setProperty('preferenceModes', value ? [] : null)
        } else {
          this.transportNote.preferenceModes = value ? [] : null
        }
      }
    },
    preferenceModes: {
      get() {
        return this.participant
          ? this.$get(this.participant, 'transportNote.preferenceModes')
          : this.transportNote.preferenceModes
      },
      set(value) {
        if (this.participant) {
          this.setProperty('preferenceModes', value)
        } else {
          this.transportNote.preferenceModes = value
        }
      }
    },
    modes: {
      get() {
        const { PLANE, TRAIN } = this.$const.transport.modes

        const preferenceModes = this.$get(this.participant, 'transportNote.preferenceModes')

        if (!preferenceModes) return null
        if (preferenceModes.includes(TRAIN) && preferenceModes.includes(PLANE)) return BOTH
        if (preferenceModes.includes(TRAIN)) return TRAIN
        if (preferenceModes.includes(PLANE)) return PLANE

        return null
      },
      set(value) {
        const { PLANE, TRAIN } = this.$const.transport.modes

        let preferenceModes

        switch (value) {
          case PLANE:
            preferenceModes = [PLANE]
            break

          case TRAIN:
            preferenceModes = [TRAIN]
            break

          case BOTH:
            preferenceModes = [TRAIN, PLANE]
            break
        }

        if (this.participant) {
          this.setProperty('preferenceModes', preferenceModes)
        } else {
          this.transportNote.preferenceModes = preferenceModes
        }
      }
    },
    goDepartureDate: {
      get() {
        return this.participant
          ? this.$get(this.participant, 'transportNote.goDepartureDate')
          : this.transportNote.goDepartureDate
      },
      set(value) {
        if (this.participant) {
          this.setProperty('goDepartureDate', value)
        } else {
          this.transportNote.goDepartureDate = value
        }
      }
    },
    goDepartureHour: {
      get() {
        return this.participant
          ? this.$get(this.participant, 'transportNote.goDepartureHour')
          : this.transportNote.goDepartureHour
      },
      set(value) {
        if (this.participant) {
          this.setProperty('goDepartureHour', value)
        } else {
          this.transportNote.goDepartureHour = value
        }
      }
    },
    goDeparturePlace: {
      get() {
        return this.participant
          ? this.$get(this.participant, 'transportNote.goDeparturePlace')
          : this.transportNote.goDeparturePlace
      },
      set(value) {
        if (this.participant) {
          this.setProperty('goDeparturePlace', value)
        } else {
          this.transportNote.goDeparturePlace = value
        }
      }
    },
    goArrivalPlace: {
      get() {
        return this.participant
          ? this.$get(this.participant, 'transportNote.goArrivalPlace')
          : this.transportNote.goArrivalPlace
      },
      set(value) {
        if (this.participant) {
          this.setProperty('goArrivalPlace', value)
        } else {
          this.transportNote.goArrivalPlace = value
        }
      }
    },
    backDepartureDate: {
      get() {
        return this.participant
          ? this.$get(this.participant, 'transportNote.backDepartureDate')
          : this.transportNote.backDepartureDate
      },
      set(value) {
        if (this.participant) {
          this.setProperty('backDepartureDate', value)
        } else {
          this.transportNote.backDepartureDate = value
        }
      }
    },
    backDepartureHour: {
      get() {
        return this.participant
          ? this.$get(this.participant, 'transportNote.backDepartureHour')
          : this.transportNote.backDepartureHour
      },
      set(value) {
        if (this.participant) {
          this.setProperty('backDepartureHour', value)
        } else {
          this.transportNote.backDepartureHour = value
        }
      }
    },
    backDeparturePlace: {
      get() {
        return this.participant
          ? this.$get(this.participant, 'transportNote.backDeparturePlace')
          : this.transportNote.backDeparturePlace
      },
      set(value) {
        if (this.participant) {
          this.setProperty('backDeparturePlace', value)
        } else {
          this.transportNote.backDeparturePlace = value
        }
      }
    },
    backArrivalPlace: {
      get() {
        return this.participant
          ? this.$get(this.participant, 'transportNote.backArrivalPlace')
          : this.transportNote.backArrivalPlace
      },
      set(value) {
        if (this.participant) {
          this.setProperty('backArrivalPlace', value)
        } else {
          this.transportNote.backArrivalPlace = value
        }
      }
    },
    comments: {
      get() {
        return this.participant ? this.$get(this.participant, 'transportNote.comments') : this.transportNote.comments
      },
      set(value) {
        if (this.participant) {
          this.setProperty('comments', value)
        } else {
          this.transportNote.comments = value
        }
      }
    },
    radioItems() {
      return [{ value: true, label: this.t('yes') }, { value: false, label: this.t('no') }]
    },
    transportModes() {
      const { PLANE, TRAIN } = this.$const.transport.modes

      return [
        {
          value: PLANE,
          label: this.t('plane')
        },
        {
          value: TRAIN,
          label: this.t('train')
        },
        {
          value: BOTH,
          label: this.t('both')
        }
      ]
    },
    today() {
      const date = new Date()
      return date.toISOString()
    },
    preferTrain() {
      if (!this.preferenceModes) return false

      return this.preferenceModes.includes(this.$const.transport.modes.TRAIN)
    },
    preferPlane() {
      if (!this.preferenceModes) return false

      return this.preferenceModes.includes(this.$const.transport.modes.PLANE)
    }
  },
  methods: {
    t(key, params) {
      return this.$t(`mybb.subscriptionForm.transport.${key}`, params)
    },
    setProperty(property, value) {
      const transportNote = this.$get(this.participant, 'transportNote', {})
      this.$emit('input', {
        ...this.participant,
        transportNote: { ...transportNote, [property]: value }
      })
    }
  },
  updated() {
    if (!this.goDepartureDate) this.goDepartureDate = new Date()
    if (!this.backDepartureDate) this.backDepartureDate = new Date()
  }
}
</script>

<style lang="scss">
.Transport-alignBottom {
  display: flex;

  > div {
    align-self: flex-end;
  }
}
</style>
